/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function r(r, e, o, n) {
  var t,
    s = arguments.length,
    i = s < 3 ? e : null === n ? n = Object.getOwnPropertyDescriptor(e, o) : n;
  if ("object" == typeof Reflect && "function" == typeof Reflect.decorate) i = Reflect.decorate(r, e, o, n);else for (var c = r.length - 1; c >= 0; c--) (t = r[c]) && (i = (s < 3 ? t(i) : s > 3 ? t(e, o, i) : t(e, o)) || i);
  return s > 3 && i && Object.defineProperty(e, o, i), i;
}
function e(r, e) {
  return function (o, n) {
    e(o, n, r);
  };
}
function o(r, e, o, n) {
  function t(r) {
    return r instanceof o ? r : new o(function (e) {
      e(r);
    });
  }
  return new (o || (o = Promise))(function (o, s) {
    function i(r) {
      try {
        f(n.next(r));
      } catch (e) {
        s(e);
      }
    }
    function c(r) {
      try {
        f(n.throw(r));
      } catch (e) {
        s(e);
      }
    }
    function f(r) {
      r.done ? o(r.value) : t(r.value).then(i, c);
    }
    f((n = n.apply(r, e || [])).next());
  });
}
function n(r, e, o) {
  if (null != e) {
    if ("object" != typeof e && "function" != typeof e) throw new TypeError("Object expected.");
    var n, t;
    if (o) {
      if (!Symbol.asyncDispose) throw new TypeError("Symbol.asyncDispose is not defined.");
      n = e[Symbol.asyncDispose];
    }
    if (void 0 === n) {
      if (!Symbol.dispose) throw new TypeError("Symbol.dispose is not defined.");
      n = e[Symbol.dispose], o && (t = n);
    }
    if ("function" != typeof n) throw new TypeError("Object not disposable.");
    t && (n = function () {
      try {
        t.call(this);
      } catch (r) {
        return Promise.reject(r);
      }
    }), r.stack.push({
      value: e,
      dispose: n,
      async: o
    });
  } else o && r.stack.push({
    async: !0
  });
  return e;
}
var t = "function" == typeof SuppressedError ? SuppressedError : function (r, e, o) {
  var n = new Error(o);
  return n.name = "SuppressedError", n.error = r, n.suppressed = e, n;
};
function s(r) {
  function e(e) {
    r.error = r.hasError ? new t(e, r.error, "An error was suppressed during disposal.") : e, r.hasError = !0;
  }
  var o,
    n = 0;
  function s() {
    for (; o = r.stack.pop();) try {
      if (!o.async && 1 === n) return n = 0, r.stack.push(o), Promise.resolve().then(s);
      if (o.dispose) {
        var t = o.dispose.call(o.value);
        if (o.async) return n |= 2, Promise.resolve(t).then(s, function (r) {
          return e(r), s();
        });
      } else n |= 1;
    } catch (i) {
      e(i);
    }
    if (1 === n) return r.hasError ? Promise.reject(r.error) : Promise.resolve();
    if (r.hasError) throw r.error;
  }
  return s();
}
export { r as _, e as a, n as b, s as c, o as d };